export default {
  components: {},
  data() {
    return {

    };
  },
  methods: {
    back(){
      this.$router.go(-1);
    },

  },
};
